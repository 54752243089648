import React, { useEffect, useState } from "react";

import { currentCountryVar, currentUserVar, useReactiveVar } from "@apollo";
// Misc Imports
import { useFirstSignIn, useOffers, useSocialAuthListener, useUpdateUser } from "@hooks";
import { makeStyles } from "@styles";
import { AuthUtils, filterOffers, ModalHelper, sortOffersForCountry } from "@utils";

// Component imports
import {
  ConfettiComponent,
  FiltersHeader,
  OfferCardsContainer,
  OnlineHeader,
} from "@components";
import { Grid } from "@material";
import { sendSegmentScreenEvent } from "helpers/analytics";

const useStyles = makeStyles((theme: Theme) => ({
  pageContent: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    minHeight: "100vh",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  cardContainer: {
    width: "100%",
    maxWidth: 1400,
    marginTop: 5,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 36,
  },
}));

interface OnlineScreenProps {
  location: {
    search: string;
    state: {
      signIn: boolean;
      currencyCode: string;
    };
  };
}

const OnlineScreen = ({ location }: OnlineScreenProps) => {
  const classes: any = useStyles({});
  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) {
      sendSegmentScreenEvent("onlineOffersScreen", {
        email: user.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        country: country ? country.name : "",
      });
    }
  }, []);

  const { updateUserMetadata } = useUpdateUser();

  // Auth code
  const socialAuth: boolean = !!(location?.search?.substring(0, 6) === "?code=") || false;
  useSocialAuthListener();

  const emailAuth = location?.state?.signIn;

  useEffect(() => {
    if (socialAuth)
      ModalHelper.open({
        modalType: "authOverlayModal",
        modalOptions: { disableBackdropClick: true },
      });
    if (emailAuth) {
      (async () => {
        ModalHelper.open({
          modalType: "authOverlayModal",
          modalOptions: { disableBackdropClick: true },
        });
        const { currencyCode } = location.state;
        await AuthUtils.completeSignIn({
          currencyCode,
          updateUserMetadata,
        });
      })();
    }
  }, [socialAuth, emailAuth]);

  // Handles first sign in of new user - display email opt in and confetti modals
  // Also updates the fields didLogin and saves user metadata
  const [confetti, setConfetti] = useState<boolean>(false);
  useFirstSignIn({ user, setConfetti });

  // Filters for Offers
  const initialFiltersState: OnlineScreenFilters = {
    category: "ALL",
    order: "Featured",
    display: "Cards",
    searchString: "",
  };
  const [filters, setFilters] = useState<OnlineScreenFilters>(initialFiltersState);

  // Offers data fetching and ordering per country
  const { offers, displayOrders } = useOffers();
  const country = useReactiveVar(currentCountryVar);
  const [displayedOffers, setDisplayedOffers] = useState<StaticOffer[]>([]);
  useEffect(() => {
    if (country) {
      const orderedOffersForCountry = sortOffersForCountry({
        offers,
        displayOrders,
        countryId: country.id,
      });

      const filteredOffers: StaticOffer[] = filterOffers(
        orderedOffersForCountry,
        filters,
      );

      setDisplayedOffers(filteredOffers);
    }
  }, [filters, country]);

  return (
    <div className={classes.pageContent}>
      {confetti && <ConfettiComponent confetti={true} />}
      <Grid item xs={12}>
        <OnlineHeader />
      </Grid>
      <FiltersHeader filters={filters} setFilters={setFilters} />
      <Grid className={classes.cardContainer}>
        {!!displayedOffers.length && (
          <OfferCardsContainer display={filters.display} offers={displayedOffers} />
        )}
      </Grid>
    </div>
  );
};

export default OnlineScreen;
